import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
export const revive_strategies = [
    {
        id: 'RV01',
        description: '相對強弱01',
        config: {
            displayName: 'RV01',
            query: SignalWallId.RV01,
            enabled: true,
        },
    },
    {
        id: 'RV01',
        description: '相對強弱02',
        config: {
            displayName: 'RV02',
            query: SignalWallId.RV02,
            enabled: true,
        },
    },
    {
        id: 'RV01',
        description: '相對強弱03',
        config: {
            displayName: 'RV03',
            query: SignalWallId.RV03,
            enabled: true,
        },
    },
];
