import { revive_store } from '~/pages/daddy960_revive/revive_store';
import { useSnapshot } from 'valtio';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const StockState = memo(function StockState() {
    const state = useSnapshot(revive_store);
    const macdState = state.macdState;
    const lifeLine = state.lifeLineState;
    return (<styleds.container>
      {/* <span css={fill_horizontal_all_center}>生死狀</span> */}
      <styleds.state.container>
        <styleds.state.content css={css `
            ${fill_horizontal_cross_center};
            color: ${macdState === 'golden' ? '#aa0000' : '#009900'};
            border: 1px solid ${macdState === 'golden' ? '#aa0000' : '#009900'};
            padding: 2px;
          `}>
          <div css={css `
              ${fill_horizontal_all_center};
            `}>
            {StateIcon(macdState)}
          </div>
          <div css={css `
              ${fill_vertical_cross_center};
            `}>
            <div>MACD</div>
            <div>{macdState === 'golden' ? '黃金交叉' : '死亡交叉'}</div>
          </div>
        </styleds.state.content>
        <styleds.state.content css={css `
            ${fill_horizontal_cross_center};
            color: ${lifeLine === 'golden' ? '#aa0000' : '#009900'};
            border: 1px solid ${lifeLine === 'golden' ? '#aa0000' : '#009900'};
            padding: 2px;
          `}>
          <div css={css `
              ${fill_horizontal_all_center};
            `}>
            {StateIcon(lifeLine)}
          </div>
          <div css={css `
              ${fill_vertical_cross_center};
            `}>
            <div>生命線</div>
            <div>{lifeLine === 'golden' ? '黃金交叉' : '死亡交叉'}</div>
          </div>
        </styleds.state.content>
      </styleds.state.container>
    </styleds.container>);
});
function StateIcon(state) {
    if (state === 'golden') {
        return (<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 24 24' stroke={'#aa0000'}>
        <path d='M16.5 8c0 1.5-.5 3.5-2.9 4.3.7-1.7.8-3.4.3-5-.7-2.1-3-3.7-4.6-4.6-.4-.3-1.1.1-1 .7 0 1.1-.3 2.7-2 4.4C4.1 10 3 12.3 3 14.5 3 17.4 5 21 9 21c-4-4-1-7.5-1-7.5.8 5.9 5 7.5 7 7.5 1.7 0 5-1.2 5-6.4 0-3.1-1.3-5.5-2.4-6.9-.3-.5-1-.2-1.1.3'/>
      </svg>);
    }
    return (<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 24 24' stroke={'#009900'}>
      <path d='M12 2C5.505 2 2 6.637 2 11c0 2.129 1.009 3.979 3 5.508V21h3v-3h2v3h4v-3h2v3h3v-4.493c1.991-1.528 3-3.379 3-5.507 0-4.363-3.505-9-10-9zM8 13c-1.121 0-2-1.098-2-2.5S6.879 8 8 8s2 1.098 2 2.5S9.121 13 8 13zm8 0c-1.121 0-2-1.098-2-2.5S14.879 8 16 8s2 1.098 2 2.5-.879 2.5-2 2.5z'/>
    </svg>);
}
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    /* background-color: #141721; */
    /* border-right: 2px solid #2b2e38; */
    border: 1px solid #565656;
    /* gap: 8px; */

    width: 100%;
    height: 100%;
  `,
    state: {
        container: styled.div `
      ${fill_horizontal_all_center};
      /* height: 40px; */
      column-gap: 2px;
      /* padding: 2px; */
      /* margin: 2px; */
      /* display: grid;
      grid-template-columns: 30% 70%; */
    `,
        content: styled.div `
      ${fill_vertical_cross_center};
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
    `,
    },
};
