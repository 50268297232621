import { proxy } from 'valtio';
import { FuiSignals } from '~/pages/heineken_template/components/FuiSignals';
import { revive_strategies } from '~/pages/daddy960_revive/revive_strategies';
export const revive_store = proxy({
    signals: new FuiSignals({
        configs: revive_strategies.map(strategy => strategy.config),
    }),
    stockSelectionType: 'long',
    stockSelectionCondition: [],
    stockSymbol: ['2330'],
    filterStockFutures: false,
    sidePane2State: 'stock',
    macdState: 'golden',
    lifeLineState: 'golden',
});
