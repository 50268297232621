import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolPlatformChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill';
import { apirc } from '~/configs/apirc';
import useMedia from '~/hooks/useMedia';
import { useSnapshot } from 'valtio';
import { staticStore } from '../heineken_template/_private/staticStore';
import { revive_store } from './revive_store';
export const Revive_SidePane1 = memo(function Revive_SidePane1() {
    const { isPc } = useMedia();
    const state = useSnapshot(revive_store);
    const origin = apirc.stock.screenerAPI.dailyRank.useSWR({
        order_by: 'desc',
        sort_by: 'change_percent',
        amount_gte: 10e8,
        amount_lte: 10e12,
        volume_gte: 10000,
        volume_lte: 99999,
    }, {
        refreshInterval: 10 * 1000,
        refreshWhenHidden: false,
        refreshWhenOffline: false,
        revalidateOnFocus: false,
        keepPreviousData: true,
    });
    const data = origin?.data?.map(s => s.symbol);
    const staticState = useSnapshot(staticStore);
    const stockFutures = staticState.symbol.stockFuturesMapper;
    const stockFuturesSymbol = Object.keys(stockFutures ?? []);
    const filterFutures = data?.filter(item1 => stockFuturesSymbol?.some(item2 => item2 === item1));
    const result = state.filterStockFutures === true ? filterFutures : data;
    return (<styleds.container>
      <styleds.card height={'calc(100% - 0px)'}>
        <SymbolPlatformChangeFill symbol={result ?? []} watchListGroup1='revive_group_1' watchListGroup2='revive_group_2' watchListGroup3='revive_group_3' watchlistAddType='multiple'/>
      </styleds.card>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
    card: styled.div `
    ${fill_vertical_all_center};
    height: ${props => props.height};
    background-color: #25262a;
    border-radius: 8px;
  `,
};
