import { MantineProvider } from '@mantine/core';
import React, { memo } from 'react';
import { useUpdateEffect } from 'react-use';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useIntervalNow } from '~/hooks/useIntervalNow';
import { revive_store } from '~/pages/daddy960_revive/revive_store';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolListChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill';
export const Revive_XqScreener = memo(function Revive_XqScreener() {
    const colorTheme = useThemeStore(state => state.theme);
    const signalsState = useSnapshot(revive_store.signals.store);
    // console.log('signalsState', signalsState)
    useUpdateEffect(() => {
        revive_store.signals.fetchAndUpdate();
    }, [signalsState.from, signalsState.to]);
    useIntervalNow(() => {
        revive_store.signals.fetchAndUpdate();
    }, 1000 * 30);
    const xqStocks = signalsState.data.map(signal => signal.symbol);
    return (<MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar>
        <styleds.title>策略選股</styleds.title>
        <styleds.SimpleQuoteListInteract>
          <styleds.DatePicker.container>
            <styleds.DatePicker.title>選擇日期：</styleds.DatePicker.title>
            <styleds.DatePicker.content>
              <revive_store.signals.DatePicker />
            </styleds.DatePicker.content>
          </styleds.DatePicker.container>

          <>
            {signalsState.data.length === 0 ? (<div css={fill_vertical_all_center}>目前沒有資料或選擇其他日期</div>) : (<styleds.SymbolListContent>
                <SymbolListChangeFill symbol={xqStocks ?? []} watchListGroup1='revive_group_1' watchListGroup2='revive_group_2' watchListGroup3='revive_group_3' sort={false}/>
              </styleds.SymbolListContent>)}
          </>
        </styleds.SimpleQuoteListInteract>
      </styleds.SideBar>
    </MantineProvider>);
});
export const styleds = {
    title: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    background-color: #3c3c49;
    border-radius: 4px;
  `,
    SideBar: styled.div `
    ${fill_vertical_cross_center}
    background-color: #16161e;
    gap: 4px;
    padding: 4px;
    padding: 4px;
  `,
    ScreenerContent: styled.div `
    ${fill_vertical_cross_center}
    width: 100%;
    height: calc(100% - 48px);
    gap: 4px;
  `,
    TitleString: styled.div `
    ${fill_vertical_all_center}
    width: 100%;
    height: 32px;
    background-color: #333333;
    border-radius: 5px;
    font-size: 16px;
  `,
    IntradayTrendChart: styled.div `
    width: 100%;
    height: 180px;
  `,
    SimpleQuoteListTXF: styled.div `
    width: 100%;
    height: 132px;
  `,
    SimpleQuoteListInteract: styled.div `
    width: 100%;
    height: 100%;
  `,
    DatePicker: {
        container: styled.div `
      ${fill_horizontal_all_center}
      /* background-color: #252525; */
      width: 100%;
      height: 45px;
      padding: 2px;
    `,
        title: styled.div `
      ${fill_horizontal_all_center}
      /* height: 32px; */
      /* background-color: #333333; */
      height: 100%;
      font-size: 16px;
      /* flex-shrink: 0; */
    `,
        content: styled.div `
      ${fill_horizontal_all_center}
      height: 100%;
      /* background-color: #252525; */
    `,
    },
    SymbolListContent: styled.div `
    ${fill_vertical_all_center}
    width: 100%;
    height: calc(100% - 50px);
    background-color: #25262a;
    padding-top: 4px;
  `,
};
